/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";
.iti.iti--allow-dropdown {
    padding-left: 50px !important;
}
ul#iti-0__country-listbox {
    position: relative !important;
    height: 250px !important;
    z-index: 90000 !important;
}
.modal.show .modal-dialog {
    top: 30% !important;
}
.modal-dialog {
    top: 30% !important;
}
.volSlider .mat-slider-track-fill {
    background-color: #4f46e5 !important;
    height: 8px !important;
}
.volSlider .mat-slider-track-background {
    background-color: rgb(223, 223, 223) !important;
    height: 8px !important;
}
.mat-slider-track-fill {
    background-color: #4f46e5 !important;
    height: 8px !important;
}
.mat-slider-track-background {
    background-color: rgb(223, 223, 223) !important;
    height: 8px !important;
}
.mat-slider-thumb {
    background-color: #fff !important;
    box-shadow: 0px 1px 4px rgba(113, 113, 113, 0.59) !important;
}
.mat-slider-horizontal .mat-slider-track-wrapper {
    height: 3px !important;
}

.font-bold {
    font-family: "Inter var" !important;
}
.font-medium {
    font-family: "Inter var" !important;
}
.font-semibold {
    font-family: "Inter var" !important;
}
p , h1, h2, h3, h4, h5, h6, span, a, button, label, input, select, textarea ,div, mat-label ,mat-form-field-label ,mat-select , mat-option, mat-option span, mat-option mat-pseudo-checkbox, mat-option mat-pseudo-checkbox .mat-pseudo-checkbox-inner, mat-option mat-pseudo-checkbox .mat-pseudo-checkbox-background, mat-option mat-pseudo-checkbox .mat-pseudo-checkbox-checkmark{
    font-family: "Inter var" !important;
}
fuse-vertical-navigation
    .fuse-vertical-navigation-wrapper
    .fuse-vertical-navigation-content
    > fuse-vertical-navigation-group-item {
    margin-top: 0px !important;
}
